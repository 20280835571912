/*
 * @Description:
 * @Author: maozhixin
 * @Date: 2022-07-13 17:43:26
 * @LastEditors: maozhixin
 * @LastEditTime: 2022-07-28 10:56:15
 */
import { ProBreadcrumb, RouteContext } from '@ant-design/pro-layout';
import { Breadcrumb } from 'antd';
import React, { useContext } from 'react';
import { Link } from 'umi';
import styles from './index.less';

const hasBreadcrumbheight = 'calc(100vh - 48px)';

export const PageContent: React.FC = (props) => {
  const value = useContext(RouteContext);
  // @ts-ignore
  let { routes } = value?.breadcrumb;

  return (
    <div className={styles.content}>
      <div>
        {/* <ProBreadcrumb className={styles.breadcrumb} /> */}
        <Breadcrumb className={styles.breadcrumb}>
          {routes?.map((item: any) => {
            return item.component ? (
              <Breadcrumb.Item>
                <Link to={item.path}>{item.breadcrumbName}</Link>
              </Breadcrumb.Item>
            ) : (
              <Breadcrumb.Item>
                <span>{item.breadcrumbName}</span>
              </Breadcrumb.Item>
            );
          })}
        </Breadcrumb>
        {value.currentMenu?.path !== '/HomePage' ? null : (
          <div style={{ height: '43px', lineHeight: '48px', color: '#1D1D1F' }}>
            {value.currentMenu?.name}
          </div>
        )}
      </div>
      <div
        className={styles.box}
        // style={{
        //   height: value.currentMenu?.path === '/HomePage' ? hasBreadcrumbheight : '100%',
        // }}
      >
        {props.children}
      </div>
    </div>
  );
};
