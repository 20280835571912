import { PageLoading, getMenuData, MenuDataItem } from '@ant-design/pro-layout';
import { message, notification } from 'antd';
import { history, Link, Route } from 'umi';
import RightContent from '@/components/RightContent';
import { PageContent } from '@/components/PageContent';
import { customerResponseInterceptor, urlRequestInterceptor } from './services/api';
import { RequestConfig } from '@@/plugin-request/request';
import { RunTimeLayoutConfig } from '@@/plugin-layout/layoutExports';
import { createRef } from 'react';
import userImg from '@/assets/user.png';
import routes from './../config/routes';
import _ from 'lodash';
import HxCrypto from '@/utils/Crypto';
import { BaseMenuProps } from '@ant-design/pro-layout/lib/components/SiderMenu/BaseMenu';
import logoImg from '@/assets/user/logo.svg';
HxCrypto.init({
  key: '2858310341595137',
  iv: '2890256383840258',
});

const loginPath = '/user/login';

/** 获取用户信息比较慢的时候会展示一个 loading */
export const initialStateConfig = {
  loading: <PageLoading />,
};
const hasRoutes: any = [];

/**
 * @see  https://umijs.org/zh-CN/plugins/plugin-initial-state
 * */
export async function getInitialState(): Promise<any> {
  // TODO: 获取用户信息
  const fetchUserInfo = async () => {
    try {
      const userInfo = JSON.parse(localStorage.getItem('userInfo') as string);
      const { id, sysRoles, nickname } = userInfo;
      const userInfo1 = {
        userId: id,
        role: sysRoles,
        name: nickname,
        avatar: userImg,
      };
      return userInfo1;
    } catch (error) {
      history.push(loginPath);
    }
    return undefined;
  };
  // TODO: 获取菜单信息
  const fetchMenuInfo = async () => {
    return [];
  };
  // 如果是登录页面，不执行
  if (history.location.pathname !== loginPath) {
    const currentUser = await fetchUserInfo();
    const hasRoutes = await fetchMenuInfo();
    return {
      isAdmin: true,
      fetchUserInfo,
      currentUser,
      settings: {},
      hasRoutes,
      selectedKeys: [],
      openkeys: [],
    };
  }
  return {
    currentMenu: null,
    isAdmin: true,
    fetchUserInfo,
    settings: {},
    hasRoutes,
    selectedKeys: [],
    openkeys: [],
  };
}

/**
 * 异常处理程序
 200: '服务器成功返回请求的数据。',
 201: '新建或修改数据成功。',
 202: '一个请求已经进入后台排队（异步任务）。',
 204: '删除数据成功。',
 400: '发出的请求有错误，服务器没有进行新建或修改数据的操作。',
 401: '用户没有权限（令牌、用户名、密码错误）。',
 403: '用户得到授权，但是访问是被禁止的。',
 404: '发出的请求针对的是不存在的记录，服务器没有进行操作。',
 405: '请求方法不被允许。',
 406: '请求的格式不可得。',
 410: '请求的资源被永久删除，且不会再得到的。',
 422: '当创建一个对象时，发生一个验证错误。',
 500: '服务器发生错误，请检查服务器。',
 502: '网关错误。',
 503: '服务不可用，服务器暂时过载或维护。',
 504: '网关超时。',
 * @see https://beta-pro.ant.design/docs/request-cn
 */
export const request: RequestConfig = {
  requestInterceptors: [urlRequestInterceptor],
  responseInterceptors: [customerResponseInterceptor],
  prefix: BASE_URL,
  errorHandler: (error: any) => {
    const { response } = error;
    if (!response) {
      notification.error({
        description: '您的网络发生异常，无法连接服务器',
        message: '网络异常',
      });
    }
    throw error;
  },
};
// 将{text: 1}转换成{label: 1},changeObjectKey({text: 1}, {text: label})
const changeObjectKey = (obj: Record<string, any>, keyMap: Record<string, string>) => {
  const result: any = _.cloneDeep(obj);
  Object.keys(keyMap).forEach((key) => {
    delete result[key];
    result[keyMap[key]] = obj[key];
  });
  return result;
};
const routeToMenu = (route: any[]) => {
  if (!Array.isArray(route)) return [];
  let data = _.cloneDeep(route);
  data = data.map((item: any) => {
    return changeObjectKey(item, {
      name: 'name',
      path: 'path',
      jumpWay: 'jumpWay',
      subMenus: 'children',
    });
  });
  data.forEach((item: any) => {
    if (item.children?.length) {
      item.children = routeToMenu(item.children);
      if (item.path == '/deviceMonitor') {
        item.children.push({
          name: '运行数据',
          path: 'runningData',
          jumpWay: item.jumpWay,
          hideInMenu: true,
        });
      }
    }
    if (item.children?.length === 0) {
      delete item.children;
    }
  });
  return data;
};
// 可以手动刷新菜单
export const layoutActionRef = createRef<{ reload: () => void }>();

// ProLayout 支持的api https://procomponents.ant.design/components/layout
export const layout: RunTimeLayoutConfig = ({ initialState, setInitialState }: any) => {
  return {
    logo: logoImg,
    title: '农机车联网平台',
    siderWidth: 250,
    isChildrenLayout: true,
    disableContentMargin: false,
    waterMarkProps: {
      content: initialState?.currentUser?.name,
    },
    openKeys: false,
    actionRef: layoutActionRef,
    collapsedButtonRender: false,
    // menuProps: {
    //   inlineIndent: 32,
    //   // openKeys: initialState?.openKeys,
    //   onOpenChange: (e) => {
    //     setInitialState({ ...initialState });
    //   },
    // },
    menuProps: {
      defaultOpenKeys: ['/deviceMonitor', '/equipmentManage'],
      defaultSelectedKeys: ['/HomePage'],
    },
    menuItemRender: (itemProps: MenuDataItem, defaultDom: any, props: BaseMenuProps) => {
      if (itemProps.jumpWay == 'blank') {
        return <a onClick={() => window.open(itemProps.path)}>{defaultDom}</a>;
      } else {
        return <Link to={itemProps.path}>{defaultDom}</Link>;
      }
    },
    menu: {
      params: {},
      request: async (params: any, defaultMenuData: any) => {
        try {
          const user: any = localStorage.getItem('userInfo');
          const userInfo: any = JSON.parse(user);
          const { menuList } = userInfo;
          const menus = routeToMenu(menuList[0].subMenus);
          return Promise.resolve(menus);
        } catch {}
        return Promise.resolve([]);
      },
      ignoreFlatMenu: true,
    },
    route: routes,
    onPageChange: (e) => {
      const { location } = history;

      // 如果没有登录，重定向到 login
      if (!initialState?.currentUser && location.pathname !== loginPath) {
        history.push(loginPath);
        return;
      }
      const { pathname } = location;
      let list = pathname.split('/');
      if (list.length > 1) {
        setInitialState({
          ...initialState,
          openKeys: [
            `/${list.find((item) => {
              return !!item;
            })}`,
          ],
        });
      }
    },
    // breadcrumbProps: {
    //   itemRender: (route: any, params: any, routes: Route[], paths: string[]) => {
    //     const last = routes.indexOf(route) === routes.length - 1;
    //     const componet = route.component;
    //     return !componet ? (
    //       <span>{route.breadcrumbName}</span>
    //     ) : last ? (
    //       <span>{route.breadcrumbName}</span>
    //     ) : route.breadcrumbName === '农机状态' ? (
    //       initialState.currentMenu ? (
    //         <Link to={initialState.currentMenu.path}>
    //           {initialState.currentMenu.breadcrumbName}
    //         </Link>
    //       ) : (
    //         <Link to={route.path}>{route.breadcrumbName}</Link>
    //       )
    //     ) : (
    //       <Link to={route.path}>{route.breadcrumbName}</Link>
    //     );
    //   },
    // },
    rightContentRender: () => <RightContent />,
    breadcrumbRender: (routers: any) => {
      return [...routers];
    },
    childrenRender: (dom: Element) => {
      return <PageContent>{dom}</PageContent>;
    },
    // 自定义 403 页面
    ...initialState?.settings,
  };
};
